import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-54d7da90"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "pluginsVideo"
};
const _hoisted_2 = ["src"];
import { thumb } from "@/utils/index";
export default {
  __name: 'Video',
  props: {
    data: {
      type: Object,
      default: () => {
        return {
          url: "",
          poster: ""
        };
      }
    }
  },
  setup(__props) {
    const props = __props;
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("video", {
        src: _unref(thumb)(props.data),
        controls: ""
      }, null, 8, _hoisted_2)]);
    };
  }
};